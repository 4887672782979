import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function DynaSysTriumphsTwiceatHBLPasha() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>Dynasys Triumphs Twice at HBL Pasha</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="DynaSys Triumphs Twice at HBL P@SHA ICT Awards 2024"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Mon 23 Sep, 2024"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
              <Text  text="<span>Islamabad, Pakistan – 23 September 2024    </span>  – DynaSys Networks, a leading innovator in Internet of Things technologies and services, is thrilled to announce that it won two awards at Pakistan’s prestigious HBL P@SHA ICT Awards 2024. DynaSys has been recognized for its exceptional contributions in the following categories:" />
              <Text Margin="30px 0px 30px 0px" text="<span>Internet of Things: </span> DynaSys&#39; cutting-edge IoT ecosystem has been lauded for its innovation, impact, and potential to revolutionize industries across Pakistan." />
              <Text   text="<span>Startup: </span>DynaSys' journey as a dynamic and promising startup has been acknowledged, highlighting its rapid growth, innovative spirit, and potential to drive positive change in the technology landscape." />
              <Text Margin="30px 0px 30px 0px" text="This dual recognition at Pakistan’s “Oscars of ICT” is a testament to DynaSys&#39; commitment to excellence, technological advancements, and its dedication to shaping a brighter digital future for Pakistan."/>
               <Text text="P@SHA is Pakistan’s association of IT and IT enabled companies. With 1600+ members, P@SHA is supporting its member organizations in building a conducive environment to operate while lobbying with the Government, building a global brand and visibility, and initiating programs to build skills and capacity of the IT industry of Pakistan."/>
              <Text Margin="30px 0px 30px 0px" text="During the award ceremony Ali Akhtar, Founder and CEO of DynaSys Networks stated “We are deeply honored to receive these prestigious awards. This recognition is a validation of our team&#39;s hard work, dedication, and unwavering pursuit of innovation. We are committed to continuing our mission of empowering businesses and individuals with cutting-edge IoT solutions.&quot;" />
            </div>
            <div className="PressReleaseImage">
              <ImageComp Src="/Images/DynaSysTriumphsTwiceatHBLPressRelease.png" Width="100%" Margin="50px 0px 0px 0px" />
            </div>
            <div className="MousCeremonyPressImageCaption" >
              <Text  Margin="50px 0px 30px 0px" FontSize="1.2rem" text="Shahid Manzoor, Manager Sales and BD (Left), Ali Akhtar, Founder and CEO of DynaSys Networks (Center), Uzair Akhtar, Product Specialist - IoT (Right)." />
              </div>
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}

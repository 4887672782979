import React from "react";
import "../Styles/PressRelease.css";
import { Helmet } from "react-helmet";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import "../Styles/MousSigningCeremony.css";

export default function DynaSysNetworksPioneersPakistan() {
  return (
    <div className="PressRelease">
      <Helmet>
        <title>DynaSys Networks Pioneers Pakistan's IoT Future</title>
      </Helmet>
      
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            text="Media Center   <span>/</span>"
            text1="  Press Releases"
            RouteLink1="/press_releases"
            Color1="#6C6C6C"
            Color2="black"
          />
          <div className="PressReleaseBody">
          <div className="MousSigningCeremonyPress">
          <div className="PressReleaseBodyTopText">
              <Text
                text="DynaSys Networks Pioneers Pakistan's IoT Future: The Country’s First Commercial IoT LoRaWAN Network Goes Live"
                FontWeight="900"
                FontSize={"3.5rem"}
                font
                Margin="50px 0px 0px 0px"
              />
              <Text
                text="Tue 19 Nov, 2024"
                FontWeight="500"
                Color="#808080"
                FontSize={"1.8rem"}
                font
                Margin="20px 0px 50px 0px"
              />
            </div>
            <div className="MousCeremonyContent">
              <Text  text="<span>Islamabad, Pakistan – 19th Nov 2024 – </span>DynaSys Networks, a leading Internet of Things (IoT) network operator and service provider, is thrilled to announce the successful go-live status of the first commercial IoT LoRaWAN Network in Pakistan compliant with Pakistan Telecommunication Authority’s (PTA) LP-WAN IoT licensing regime. This significant milestone marks a pivotal moment in the country's IoT journey, paving the way for widespread adoption of low-power, long-range wireless IoT technologies." />
              <Text Margin="30px 0px 30px 0px" text="IoT refers to the network of physical devices that are embedded with sensors, software, and network connectivity, allowing them to collect and share data for data driven decision making. These devices can range from plain home devices like smart thermostats to complex industrial machinery and transportation systems. IoT solutions improve operational efficiency, optimize resource management, and unlock new revenue streams, ultimately leading to a more connected and intelligent future." />
              <Text   text="LoRaWAN is a low-power, wide-area network (LPWAN) protocol designed for long-range, low-power wireless applications. It is ideal for IoT deployments as it offers several benefits, including long-range communication, low power consumption, scalability and security. It is used in a wide range of applications, including smart agriculture, smart cities, asset tracking, environmental monitoring, and industrial automation." />
              <Text Margin="30px 0px 30px 0px" text="At the heart of DynaSys’ LoRaWAN Network in Pakistan, is its unique IoT platform which is the only one in the country with an embedded LoRa Network Server. The entire system is hosted in data centers within Pakistan ensuring data sovereignty, privacy and protection of the end users in full compliance with the laws of the land."/>
               <Text text="Ali Akhtar, CEO and Founder of DynaSys Networks stated on the occasion, “These are historic times for IoT in Pakistan. We have become the first LP-WAN Licensee in the country, to operationalize a commercial LoRaWAN network allowing customers to enjoy the full benefits of numerous IoT use cases. The first set of gateways and sensors have been implemented in the twin cities of Islamabad and Rawalpindi with use cases focused on air quality and soil monitoring. There are many more use cases across various industries and aspects of life that we will be implementing in the months ahead. Exciting times!”"/>
            </div>
            <div className="PressReleaseImage">
              <ImageComp Src="/Images/DynasysNetworkPioneerPressRelease.png" Width="100%" Margin="50px 0px 0px 0px" />
            </div>
          </div>
            
          </div>
        </div>
      </div>

      <div class="borderSec">
        <div class="border"></div>
      </div>
        
    </div>
  );
}
